<template>

  <div  class="paper" style="width:100%;left: 0; ">
    <div v-if="item != undefined" class="header">
      
      <div class="home-bar" style="cursor:pointer; padding: 10px 20px; background:#ccc; border-radius:5px;" v-on:click="toHome()">
        <i class='bx bxs-home'></i>
      </div>
      <div class="control-bar row">
        <div class="profile">
          <a class="" data-toggle="dropdown">
            <i class="bx bx-user"></i>
            <p>{{ user.charAt(0).toUpperCase() + user.slice(1) }}</p>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">My profile</a>
            <a class="dropdown-item" href="#" v-on:click="logOut">Log Out</a>
          </div>
        </div>
      </div>
    </div>
    <br>
    <paper-record v-if="item != undefined && recordView != null"/>
  </div>
  <ui-modal name="subscription" type="message" >
    <template #content>
      <div style="width: 500px; margin:0 auto; text-align:center; display:block;" v-if="subData != null">
        <br>
        <h4>{{subData.updatedBy}} has just updated this record.</h4>
        <h6>If you accept changes, your changes will be lost. <br> Accept changes?</h6>
        <br>
        <p> <strong>Changes made:</strong> </p>
        <p v-for="(att, index) in subData.attNew" :key="index" style="margin: 5px 0 0 0;" ><span style="font-weight: 500;">{{att[0]}}: </span>  {{ att[1]}}  </p>
        <ui-button :style="'margin:20px 45px; width: 160px; float:left; background: '+ organizationData.colorSeriesA[0]+';'" :color="'blue'" :text="'Yes'" :position="'center'" data-dismiss="modal" aria-label="Close" v-on:click="getItem(); "/>
        <ui-button :style="'margin:20px 45px; width: 160px; float:left; background: '+ organizationData.colorSeriesA[4]+';'" :color="'gray'" :text="'No'" :position="'center'" data-dismiss="modal" aria-label="Close"/>
      </div>
    </template>
  </ui-modal>
</template>
<script>
//AWS - GRAPHQL
  import { smd_getRecordObj } from '../graphql/queries'
  import { onUpdateSmartRecord } from '../graphql/subscriptions'
  //Tools
  import Vuex from 'vuex'
  import { API, Auth } from 'aws-amplify'

  //Components
  import PaperDisplayer from '../components/papers/PaperDisplayer.vue'
  import PaperRecord from '../components/papers/PaperRecord.vue'
  import UiModal from '../components/ui/UiModal.vue'
  import UiButton from '../components/ui/UiButton.vue'
  

  export default {
    components:{
      PaperDisplayer, 
      PaperRecord,
      UiModal,
      UiButton,
    },
    data() {
      return {
        item: undefined,
        subData:null,
        record:null,
      }
    },
    created() {
      this.item = undefined
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      this.loading('show')
      console.clear()
      this.setUserWeb()
      this.subscribe()
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData',"getRecord"]),
      ...Vuex.mapMutations(['SetUser','setIdentityId','setStatusReload','setPublicOrganizationData','SetOrganizationID','setPublicOrgPay','setPublicOrganization','setContactSK', "setFormat","setDataUser","setRecord",'setCurrentItem']),
      //Tools
      loading(action){
        if (action == 'show') {
          document.getElementById("loading").classList.add('show')
          document.getElementById("body").classList.add("noScroll")
        }else{
          document.getElementById("loading").classList.remove('show')
          document.getElementById("body").classList.remove("noScroll")
        }
      },
      async currentUser(){
        const session = await Auth.currentUserCredentials();
        this.setIdentityId(session.identityId)
      },
      async setUserWeb(){
        this.loading('show')
        console.log('>>> USER: '+this.user);
        this.setCurrentItem(this.$router.currentRoute.value.query.data)
        if(this.$router.currentRoute.value.query.pass){
          console.log('Con Pass');
          Auth.signIn(this.$router.currentRoute.value.query.user, this.$router.currentRoute.value.query.pass)
          .then((user) => { 
            this.SetOrganizationID(user.attributes["custom:organizationID"]);
            this.currentUser()
            this.getPublicOrganization()//Alias
            setTimeout(() => {
              this.SetUser(user.username);
              this.getItem()
              this.loading('hidde')
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$router.push({
              path: '/smartdash',
              query:{data: 'questionnaires' }
            })
          });
        }
        else if (this.user == 'Not authorized') {
          this.$router.push({
            path: '/smartdash',
            query:{data: 'questionnaires' }
          })
        }
        else{
          this.getPublicOrganization()//Alias
          setTimeout(() => {
            this.getItem()
            this.loading('hidde')
          }, 500);
        }
      },
      async getItem(){
        let pullData = ''
        let pushData = {
          PK: this.publicOrganization,
          SK: this.$router.currentRoute.value.query.data ,//data.SK
        }
        console.log(pushData);
        try {
          
          console.log(pushData);
          pullData = await API.graphql({
            query: smd_getRecordObj,
            variables:pushData
          })
          console.log(pullData);
          pullData = pullData.data.smd_getRecordObj
          pullData = JSON.parse(pullData.data)
          console.log(pullData);
          this.setRecord(pullData); 
          this.getRecord(pullData)
          this.record = pullData
          console.log(pullData);
          this.item = true
        } catch (error) {
          console.log(error);
        }
      },
      toHome(){
        console.log(this.$router.currentRoute.value.query.src);
        console.log(window.location.origin+window.history.state.back);
        if (this.$router.currentRoute.value.query.src == 'dash') {
          console.log(window.history.state.back);
          console.log(window.history.state.back.replace('/customerdash?user=','').replace('pass=','').split('&'));
          this.$router.push({
            path: "/customerdash",
            query: {
              user: window.history.state.back.replace('/customerdash?user=','').replace('pass=','').split('&')[0],
              pass: window.history.state.back.replace('/customerdash?user=','').replace('pass=','').split('&')[1],
            },
          });
          //window.open(window.location.origin+window.history.state.back)
        }
      },
      async logOut() {
        this.loading('show')
        try {
          setTimeout(() => {
            console.log("salir");
            this.$router.push({
              path: "/smdlogin",
              query: {},
            });
            this.loading('hide')
            this.clear()
          }, 1000);
          await Auth.signOut();
        } catch (error) {
          console.log(error);
          this.loading('hide')
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong!' ,
            text: error,
            footer: 'smd_getMyOrganization'
          })
        }
      },
      //Sub
      
      subscribe(){
        API.graphql({ query: onUpdateSmartRecord }).subscribe({
          next: (eventData) => {
            this.subData = null
            let subData = eventData.value.data.onUpdateSmartRecord;
            subData = JSON.parse(subData.data)
            subData.attNew = []
            let focus = this.record
            if (subData.PK == this.organizationID ) {
              //Sub in record
              console.log('PK')
              try {
                if(subData.SK == focus.SK) {
                  if (subData.updatedBy != this.user) {
                    console.log("diferente Usuario");
                    for (const key in subData.attributes) {
                      try {
                        if (subData.attributes[key].attributeValue != focus[key].value) {
                          if (typeof subData.attributes[key].attributeValue != 'string') {
                            subData.attNew.push([subData.attributes[key].label, 'New Item'])
                          } else{
                            subData.attNew.push([subData.attributes[key].label, subData.attributes[key].attributeValue])
                          }
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }
                    this.subData = subData
                    $('#modal-subscription').modal('show')
                  }
                }
              } catch (error) {
                console.log(error)
              }
              /*
              //Notifications
              if (pullData.smd_response.notifyme == true) {
                this.checkPreloadList(this.menu)
              }
              //Alerts
              if (pullData.smd_response.popAlert == true) {
                popUp(false,pullData.smd_response,data)
              }*/
            }
          }
        });
      },
    },
    
    computed:{
      ...Vuex.mapState(['organization','user','publicOrganizationData','publicOrganization', 'currentItem','recordView','record','organizationID','organizationData'])
    },
  }
</script>